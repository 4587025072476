// import styled from 'styled-components';

function Booster({ style }) {
  return (
    <div style={ style }>
      
    </div>
  );
}

export default Booster;
