// import styled from 'styled-components';

function Stats({ style }) {
  return (
    <div style={ style }>
      Stats coming soon!
    </div>
  );
}

export default Stats;
